<!-- start chat conversation section -->
<div class="user-chat w-100 overflow-hidden user-chat-show" [class.user-chat-show]="hideUserChatValue">
    <div class="w-100 overflow-hidden position-relative" style="width: 100%; height: 100svh !important;">
        <div class="p-3 p-lg-4 border-bottom user-chat-topbar">
            <div class="row align-items-center">
                <div class="col-sm-11 col-11">
                    <div class="d-flex align-items-center">
                        <div class="d-block d-lg-none me-2 ms-0">

                            <a class="back" (click)="hideUserChatOnMObile()">
                                <i class="ri-arrow-left-line"></i> retour</a>
                        </div>
                        <div class="chat-user-img   align-self-center me-3 ms-0" [class.offline]="!this.connected"
                             [class.online]="this.connected ">
                            <a [href]="'/mon-espace?openannonce='+idAnnonce" target="_blank">
                                <img
                                        [src]="image"
                                        [ngClass]="{'fast': isFastConnect}"
                                        class=" avatar-xs" alt=""></a>
                            <span class="user-status"></span>
                        </div>
                        <div class="overflow-hidden">
                            <h5 class="font-size-16 mb-0 text-truncate"><a [href]="'/mon-espace?openannonce='+idAnnonce"
                                                                           class="text-reset user-profile-show"
                                                                           target="_blank">{{ size > 425 ? 'Discussion avec' : '' }}
                                {{ prenom }} {{ nomUser }}</a>
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="col-sm-1 col-1">
                    <ul class="list-inline user-chat-nav text-end mb-0">
                        <li class="list-inline-item">
                            <div class="dropdown">
                                <button class="btn nav-btn dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                    <i class="ri-more-fill"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-end" style="min-width: 15rem;">

                                    <a class="dropdown-item" data-bs-toggle="modal" style="cursor: pointer;"
                                       data-bs-target='#deleteModal'>Supprimer
                                        la discussion<i class="ri-delete-bin-line float-end text-muted"></i></a>
                                    <a class="dropdown-item" data-bs-toggle="modal" style="cursor: pointer;"
                                       data-bs-target='#blockModel'>Bloquer ce contact
                                        <i class="ri-forbid-line float-end text-muted"></i></a>
                                    <a class="dropdown-item" data-bs-toggle="modal" style="cursor: pointer;"
                                       data-bs-target='#reportModal'>Signaler cette personne
                                        <i class="ri-forbid-line float-end text-muted"></i></a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- end chat user head -->


        <!-- end chat input section -->
        <!-- start chat conversation -->
        <div class="chat-conversation p-3 p-lg-4"
             data-simplebar="init"
             [ngClass]="{'is-last-message-express': (isBasicLocal && isBasicDistant)}">
            <ul class="list-unstyled mb-0">


                <!-- <li>
                    <div class="chat-day-title">
                        <span class="title">Today</span>
                    </div>
                </li> -->

                <div *ngFor="let conversation of  listConversation;let i =index; let last=last">

                    <li *ngIf="conversation.id_expediteur !== this.currentUserId ">
                        <div class="conversation-list" style="margin-bottom: 2px;">

                            <div class="user-chat-content">
                                <p class="message-highlight" *ngIf="conversation?.express === 1">Message Contact
                                    Express</p>
                                <div class="ctext-wrap">

                                    <div class="ctext-wrap-content">
                                        <p class="mb-0"
                                           [class]="'************'===conversation?.contenu?'blur_text':''"
                                           [innerHTML]="transform(conversation.contenu, conversation)">
                                        </p>
                                        <a *ngIf="'************'===conversation?.contenu"
                                           class="link_p"
                                           [href]="environment.subscribeUrl + '?p=1&membre=' + conversation.expediteur.pseudo"
                                        >Lire la suite</a>
                                        <p class="chat-time mb-0"><i class="ri-time-line align-middle"></i> <span
                                                class="align-middle"> {{
                                                isToday(conversation?.date_envoi) ?
                                                    (conversation?.date_envoi | date:
                                                        "HH:mm") : (conversation?.date_envoi | date: "dd/MM/yyyy HH:mm" :'fr')
                                            }}</span>
                                        </p>
                                    </div>

                                </div>

                                <div class="chat-avatar"
                                     *ngIf="listConversation[i]?.id_destinataire !== listConversation[i +1]?.id_destinataire ">

                                    <img
                                            [src]="userService.getImageProfilPath(
                                            conversation?.expediteur.sexe,
                                            conversation?.expediteur.photo_profil,
                                            conversation?.expediteur.is_fast_connect)"
                                            [ngClass]="{'fast': conversation?.expediteur.is_fast_connect}"
                                         alt="">
                                </div>
                                <div class="chat-avatar"
                                     *ngIf="listConversation[i]?.id_expediteur === listConversation[i +1]?.id_expediteur ">
                                    <img [src]="" style="display: none;">
                                </div>

                                <div class="conversation-name" *ngIf="listConversation[i]?.id_expediteur !== listConversation[i +1]?.id_expediteur
                                    ">
                                    {{ prenom }} {{ nomUser }}
                                </div>

                                <!-- New Title and Buttons -->
                                <div *ngIf="conversation?.express === 1 && last" class="response-express">
                                    <p (click)="handlePopupInfoFastMessage()"
                                       class="link-info-express link-express-text">Comment utiliser la messagerie
                                        Express Gratuite ?
                                        <i class="ri-external-link-line"></i>
                                    </p>
                                    <p class="title-highlight">Ma réponse Gratuite</p>
                                    <div class="response-express-body">
                                        <button (click)="handleResponseYes()" class="btn-oui"><i
                                                class="ri-thumb-up-fill"></i> Oui
                                        </button>
                                        <button (click)="handleResponseNo()" class="btn-non"><i
                                                class="ri-thumb-down-fill"></i> Non
                                        </button>
                                    </div>
                                </div>
                                <!-- End of New Title and Buttons -->
                            </div>

                        </div>
                    </li>

                    <li class="right" *ngIf="conversation.id_expediteur===this.currentUserId  ">
                        <div class="conversation-list" style="margin-bottom: 2px;">

                            <div class="user-chat-content">
                                <div class="ctext-wrap">
                                    <div class="ctext-wrap-content">
                                        <p class="mb-0"
                                           [class]="'************'===conversation?.contenu?'blur_text':''"
                                           [innerHTML]="transform(conversation.contenu, conversation)">
                                        </p>
                                        <a *ngIf="'************'===conversation?.contenu"
                                           class="link_p"
                                           [href]="environment.subscribeUrl + '?p=1&membre=' + conversation.destinataire.pseudo"
                                        >Lire la suite</a>
                                        <p class="chat-time mb-0"><i class="ri-time-line align-middle"></i> <span
                                                class="align-middle">{{
                                                isToday(conversation?.date_envoi) ?
                                                    (conversation?.date_envoi | date:
                                                        "HH:mm" :'fr') : (conversation?.date_envoi | date: "dd/MM/yyyy HH:mm"
                                                        :'fr')
                                            }}</span>
                                        </p>

                                        <p *ngIf="last && conversation.lu_destinataire != 0" class="chat-time mb-0">
                                            <span class="vu"> Lu le </span> <span class=" vu_date">{{
                                                (conversation?.date_lecture | date: "dd/MM/yyyy HH:mm" :'fr')
                                            }}</span>
                                        </p>

                                    </div>

                                </div>
                                <div class="chat-avatar"
                                     *ngIf="listConversation[i]?.id_expediteur !== listConversation[i +1]?.id_expediteur ">
                                    <img [src]="userService.getImageProfilPath(
                                    conversation?.expediteur.sexe,
                                    conversation?.expediteur.photo_profil,
                                    conversation?.expediteur.is_fast_connect)"
                                         [ngClass]="{'fast': conversation?.expediteur.is_fast_connect}"
                                         alt="">
                                </div>
                                <div class="chat-avatar"
                                     *ngIf="listConversation[i]?.id_expediteur === listConversation[i +1]?.id_expediteur ">
                                    <img [src]="" style="display: none;">
                                </div>
                                <div class="conversation-name" *ngIf="listConversation[i]?.id_expediteur !== listConversation[i +1]?.id_expediteur
                                    ">
                                    {{
                                        conversation.expediteur.prenom ? conversation.expediteur.prenom : conversation.expediteur.pseudo
                                    }}
                                    {{ conversation.expediteur.nom }}
                                </div>

                            </div>

                        </div>
                    </li>
                </div>

                <div class=" p-2  p-lg-4 mb-0 inline-block"
                     [class.p-lg-4]="size > 768"
                     *ngIf="isBasicLocal && isBasicDistant && isLastMessageExpressOui">
                    <div class="row g-0">
                        <div class="col">
                            <a [href]="urlSubscribe" class="actu">
                                Si vous souhaitez continuer à communiquer avec {{ this.prenom }} , soit par téléphone ou
                                via la messagerie du site, vous avez
                                besoin d’un Pass Membre Privilégié» <i class="ri-external-link-line"></i>
                            </a>
                        </div>
                    </div>
                </div>

            </ul>

            <div [style.height]="(isBasicLocal && isBasicDistant && isLastMessageExpressOui) ? 0 : ((size > 768) ?'30px':'130px') "
                 #scrollBottom></div>

        </div>
        <!-- end chat conversation end -->


        <!-- start chat input section -->
        <div class="chat-input-section p-2  p-lg-4 border-top mb-0"
             [class.p-lg-4]="size > 768"
             *ngIf="!(isBasicLocal && isBasicDistant) ">

            <div class="row g-0">
                <div class="col">
                    <textarea type="text" name="messageLg" #messageLg="ngModel" [(ngModel)]="conversationData.contenu "
                              class=" form-control form-control-lg bg-light border-light message-input"
                              placeholder="Ecrire un message..."></textarea>
                </div>
                <div class="col-auto">
                    <div class="chat-input-links ms-md-2 me-md-0">
                        <ul class="list-inline mb-0">

                            <li class="list-inline-item">
                                <button id="submit" type="submit" (click)="addMessage(conversationData)"
                                        class="btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light">
                                    <i class="ri-send-plane-2-fill"></i>
                                </button>
                            </li>
                        </ul>
                    </div>

                </div>

            </div>
        </div>

    </div>

    <div class="modal fade" id="modal-express" tabindex="-1" #modal4 role="dialog"
         aria-labelledby="addgroup-exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title font-size-16" id="addgroup-exampleModalLabel">
                        Votre réponse est bien partie, son destinataire pourra la lire !</h5>
                </div>
                <div class="modal-body p-4">
                    <div class="mb-3">
                        <p class="body-modal">
                        </p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="closeModal()">Fermer</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="modal-info-express" tabindex="-1" #modal5 role="dialog"
         aria-labelledby="addgroup-exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header" style="padding: 0 1rem;">
                    <h5 class="modal-title font-size-18 bold" style="color: #2c89b7;font-weight: bold;" id="addgroup-exampleModalLabel">
                        Comment fonctionne la messagerie gratuite ?</h5>

                    <span style="font-size: 40px; color: #d02626; cursor: pointer;" (click)="closeModal()"><i class="ri-close-line"></i></span>
                </div>
                <div class="modal-body p-0">
                    <div style="margin: 0.5rem;
                              color: #2c89b7;
                              font-size: 16px;
                              font-weight: bold;">

                        <!-- Section sur l'envoi de message -->
                        <span style="font-weight: bold;">
                            <i class="ri-arrow-right-line"
                               style="color: #2c89b7;
  font-size: 23px;
  top: 6px;
  position: relative;
  margin-right: 5px;"></i>
                            Sur l’annonce d’Elodie, vous cliquez sur <img style="border-radius: 5px;
  margin-left: 5px;" src="https://ns3085209.ip-147-135-255.eu/img/info/btn-express.png" alt=""/><br/></span>
                        <span style="margin-left: 5px; display: block;
  font-size: 14px;
  color: #2c89b7;
  top: 4px;
  position: relative;">le message
                            ci-dessous part vers le destinataire « Elodie »</span>
                        <img style="display: block; width: 100%;"
                             src="https://ns3085209.ip-147-135-255.eu/img/info/message-express2.png" alt=""/>

                        <span style="font-weight: bold; margin-top: 5px; display: block;">
                                Elodie prend connaissance du message de Rémy.<br/><br/>

                            <!-- Réponse "Oui" -->
                                <i class="ri-arrow-right-line"
                                   style="color: #2c89b7;
  font-size: 23px;
  top: 6px;
  position: relative;
  margin-right: 5px;"></i>
                            Elodie clique sur <img src="https://ns3085209.ip-147-135-255.eu/img/info/oui.png" alt=""/>

                            </span><span style="margin-left: 5px; display: block;
  font-size: 14px;
  color: #2c89b7;
  top: 4px;
  margin-bottom: 3px;
  position: relative;">Lire la réponse qui part vers Rémy.</span>

                        <!-- Réponse "Non" -->
                        <img src="https://ns3085209.ip-147-135-255.eu/img/info/express2.png" style="width: 100%;"
                             alt=""/><br/>

                        <i class="ri-arrow-right-line"
                           style="color: #2c89b7;
  font-size: 23px;
  top: 6px;
  position: relative;
  margin-right: 5px;"></i>
                        <span style="font-weight: bold;">Elodie clique sur <img
                                src="https://ns3085209.ip-147-135-255.eu/img/info/non.png" alt=""/>
                            <br/>
                                </span><span style="margin-left: 5px; display: block;
  font-size: 14px;
  color: #2c89b7;
  top: 4px;
  margin-bottom: 3px;
  position: relative;">Lire la réponse qui part vers Rémy.</span>
                        <img src="https://ns3085209.ip-147-135-255.eu/img/info/ee2.png" style="width: 100%;" alt=""/>
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>


